.exit-icon{
    position: fixed;
    bottom: 62px;
    right: 40px;
    width: 77px;
    height: 75px;
    background: #FFFFFF;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
@media only screen 
and (min-device-width : 820px) 
and (max-device-width : 1180px) 
and (orientation : landscape) {
    .exit-icon{
        bottom: 85px;
        right: 15px;
        width: 55px;
        height: 50px;
}
}