.card-with-image {
  width: 336px;
  height: 428px;
  background: #ebe9e9;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 35px;
  margin: 50px;
}
.Beckn-image {
    height: 50px;
    width: 161px;
    cursor: pointer;
    position: absolute;
    left: 100px;
    top: 90px;
  }
  .headLogo-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
  }
.card-sub-containers {
  display: flex;
  list-style-type: none;
}
.card-containers {
  display: flex;
  justify-content: center;
}
.first-image-card {
  margin-top: 15px;
}
.card-title-image {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  margin-top: 15px;
}
.font-color{
  color: #4e4646;
}
.second-image-card {
  margin-top: 15px;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.page-content-title {
  font-weight: 400;
  font-size: 64px;
  line-height: 96px;
  color: #4e4646;
}
.home-buttom-nevigate{
    border-radius: 30px;
    font-size: 28px;
    font-weight: 400;
    background: #E9EBED;
    width: 150px;
    position: absolute;
    right: 100px;
    top: 90px;
    }

/* -------fade-in---------------- */

.fadeinout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.animates {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .fives {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  /*=== FADE IN  ===*/
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  .fadeIns {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    margin-top: 120px;
  }

.main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    animation: fadeinout 4s; 
}
@keyframes main-container {
100% {
    opacity: 0;
  }
}
