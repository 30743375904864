.card-container {
  box-shadow: 8px 5px 10px rgb(0 0 0 / 20%);
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  height: 90vh;
  display: flex;
  justify-content: center;
}
.card-container{
  animation:fadeInUp 1s ease backwards;
}
@keyframes fadeInUp{
  0%{transform:translate(0px, 100px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}


.border-gradient-purple {
  height: 40px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-top: 38px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
h2 {
  text-align: center;
}
li {
  text-align: center;
}
.beckn-logo {
  margin-bottom: 56px;
  width: 161px;
  height: 50px;
}
.head-text {
  font-size: 48px;
  font-weight: 400;
  color: #4e4646;
  line-height: 72px;
}
.head-child-text {
  color: #343538;
  font-weight: 275;
  font-size: 32px;
  line-height: 48px;
  margin-top: 20px;
  margin-bottom: 38px;
}
@media only screen 
and (min-device-width : 820px) 
and (max-device-width : 1180px) 
and (orientation : portrait) { 
  .card-container{
    width: 775px !important;
  }
}
